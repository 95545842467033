<template>
  <base-tooltip position="top" type="secondary">
    <template #text>
      <slot />
    </template>
    <span :class="[`status-icon status-icon--${variant}`]">!</span>
  </base-tooltip>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'secondary', // secondary, primary, both
    },
  },
};
</script>
