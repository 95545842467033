<template>
  <a :href="link.value" target="_blank" rel="nofollow">
    <img
      :src="require(`./assets/${link.image}.png`)"
      :srcset="
        `${require(`./assets/${link.image}.png`)}, ${require(`./assets/${link.image}-2x.png`)} 2x`
      "
      alt=""
    />
  </a>
</template>

<script>
export default {
  props: {
    link: Object,
  },
};
</script>
